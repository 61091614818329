import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
// import Logo from '../../../images/icon.png';
import Slider from "react-slick";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

import "./News.scss"
import { Link } from "@StarberryUtils";
import { limitedContent, GetURL } from '../../Common/site/functions';
import { LATEST_NEWS_PAGE_URL } from '../../Common/site/constants';
import moment from "moment/src/moment";
import _ from "lodash";
// import logoBlack from "../../../images/logoblack.svg";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GET_ALL_NEWS = gql`
  query GetNews{	
      newsBlogs(sort:"createdAt:desc", limit: 10, where:{Publish:true})  {
        id
        URL
        Title
        Short_Description
        Publish 
        Date 
        Tile_Image {
          url
          alternativeText
        }
      }
  }
`;


const NewsPage = (props) => {
  var settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    autoplay: true,
    className: "center",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,

        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const { loading, error, data } = useQuery(GET_ALL_NEWS);

  if (loading) return <div className="gql-loading-screen"><p>Loading ...</p></div>;

  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         <div className="new-loader-anime"></div>
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="logo" />
  //       </div>
  //       <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div>
  //     </div>
  //   </section>
  // );

  var NewsList = data?.newsBlogs;
  if (!_.isEmpty(props.newsId)) {
    NewsList = data.newsBlogs.filter(list => list.id != props.newsId);
  } 
//  NewsList =  NewsList.map((item) => ({
//     ...item,
//     timeStamp: moment(item.Date, 'YYYY-MM-DD').format("YYYYMMDD")       
// })); 
  
  NewsList = _.orderBy(NewsList, ['Date'], ['desc']); 

  let url = ''
  if (props.CTA_Link) {
    url = GetURL(props.CTA_Link.id)
  }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="news-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="news">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="block-title">
                    <span>{props.Title}</span>
                    <h2>{props.Sub_Title}</h2>
                    {props.CTA_Label &&
                      <div className="mobile_news_more_btn d-lg-none d-xl-none">
                        {props.CTA_Link && url[1] == "Internal" ?
                          <Link to={`/${url[0]}`} className="btn btn-outline">{props.CTA_Label}</Link>
                          :
                          <a href={`${url ? url[0] : ''}`} target="_blank" className="btn btn-outline">{props.CTA_Label}</a>
                        }
                      </div>
                    }
                  </div>
                </Col>
              </Row>
              <div className="news-box-wrap">
                <Slider {...settings}>
                  {NewsList && NewsList.map((item, index) => (
                    <motion.div
                      className="news-box"
                      key={index}
                      variants={delayItemsFromBottom}
                      custom={index}
                    >
                      <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${item.URL}`}>
                        <div className="properties-wrapper img-zoom">
                          <picture>
                            <source media="(min-width:1200px)" srcSet={item.Tile_Image.url} />
                            <source media="(min-width:768px)" srcSet={item.Tile_Image.url} />
                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                          </picture>
                        </div>
                      </Link>
                      <div className="newsletter-info">
                        <div className="time-info">{moment(item.Date).format("DD MMMM YYYY")}
                        </div>
                        <h3> <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${item.URL}`}>{limitedContent(item.Title, 80)}</Link> </h3>
                      </div>
                    </motion.div>
                  ))}
                </Slider>
                {props.CTA_Label &&
                  <div className="news_more">
                    {props.CTA_Link && url[1] == "Internal" ?
                      <Link to={`/${url[0]}`} className="btn btn-outline">{props.CTA_Label}</Link>
                      :
                      <a href={`${url ? url[0] : ''}`} target="_blank" className="btn btn-outline">{props.CTA_Label}</a>
                    }
                  </div>
                }

              </div>
            </Container>

          </div>
        </motion.section>
      )}
    </InView>
  )
}
export default NewsPage